export const photos = [
    {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/13.jpg",
        width: 5,
        height: 3
      },
      // {
      //   src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/14.jpg",
      //   width: 4,
      // height: 3
      // },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/15.jpg",
        width: 4,
      height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/16.jpg",
        width: 5,
        height: 3
      },
      // {
      //   src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/17.jpg",
      //   width: 5,
      //   height: 3
      // },
      // {
      //   src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/18.jpg",
      //   width: 5,
      // height: 3
      // },
      // {
      //   src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/19.jpg",
      //   width: 5,
      // height: 3
      // },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/20.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/21.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/22.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/23.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/24.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/25.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/26.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/27.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/28.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/29.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/30.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/31.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/32.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/33.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/34.jpg",
      width: 5,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/35.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/36.jpg",
      width: 5,
      height: 3
    },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/39.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/40.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/41.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/42.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/43.jpg",
        width: 4,
        height: 3
      },
    //   {
    //     src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/44.jpg",
    //     width: 4,
    //     height: 3
    //   },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/45.jpg",
        width: 5,
        height: 3
      },
    //   {
    //     src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/46.jpg",
    //     width: 4,
    //     height: 3
    //   },
    //   {
    //     src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/47.jpg",
    //     width: 4,
    //     height: 3
    //   },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/48.jpg",
        width: 5,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/49.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/50.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/93.jpg",
        width: 5,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/94.jpg",
        width: 4,
        height: 5
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/95.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/96.jpg",
        width: 5,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/97.jpg",
        width: 5,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/98.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/99.jpg",
        width: 5,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/100.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/70.jpg",
        width: 2,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/71.jpg",
        width: 2,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/72.jpg",
        width: 3,
        height: 4
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/73.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/74.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/75.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/76.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/77.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/78.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/79.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/80.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/81.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/82.jpg",
        width: 3,
        height: 4
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/83.jpg",
        width: 4,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/84.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/85.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/86.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/87.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/88.jpg",
        width: 3,
        height: 3
      },
      {
        src: "https://selectionsdesign.s3.us-east-2.amazonaws.com/89.jpg",
        width: 3,
        height: 3
      }

]
  