import React from 'react'
import './index.scss'
import {
	BrowserRouter as Router,
	Route
} from 'react-router-dom'
import Home from '../Home/index'

function App() {
  return (
    <main>
      <Router>
        <Route exact path="/" component={Home} />
      </Router>
    </main>
  )
}

export default App
