import React, { Component } from 'react'
import YouTube from 'react-youtube'
import { 
  Row,
  Col,
  Typography
} from 'antd'
import './index.scss'
import one from '../Images/Brands/1.png'
import two from '../Images/Brands/2.png'
import three from '../Images/Brands/3.png'
import four from '../Images/Brands/4.png'
import five from '../Images/Brands/5.png'
import eight from '../Images/Brands/8.png'
import six from '../Images/Brands/6.png'
import seven from '../Images/Brands/7.png'
import nine from '../Images/Brands/9.png'
import ten from '../Images/Brands/10.png'

const { Title, Text } = Typography

class Wakely extends Component {
  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      },
    };
    return (
        <div className='wakely'>
            <Row gutter={[48, 48]}>
              <Col 
                    xs={{ span: 24 }} 
                    md={{ span: 12 }}
                    className='brands--text'
                >
                <Title level={2}>
                    Proud Canadian business of exceptional quality and service for over 30 years
                </Title>
                <div style={{marginTop: '20px'}}>
                  <Text className='text'>
                      Trusted by top Canadian brands
                  </Text>
                </div>
                <div className='brands'>
                    <img src={one} alt='Hero Certified Burgers'/>
                    <img src={two} alt='Basikin Robbins'/>
                    <img src={three} alt='University of Toronto'/>
                    <img src={four} alt='University of Ryerson'/>
                    <img src={five} alt='City of Mississauga'/>
                    <img src={eight} alt='metrolinx'/>
                    <img src={six} alt='Dufferin Peel'/>
                    <img src={seven} alt='Snug Harbour'/>
                    <img src={ten} alt='Mucho Burrito'/>
                    <img src={nine} alt='City of Brampton'/>
                </div>
               
                
              </Col>
              <Col
                xs={{ span: 24 }} 
                md={{ span: 12 }}
              >
                <YouTube videoId="_P7IjAQvUHc" opts={opts} className='youtube' onReady={this.onReady} />
              </Col>
            
            </Row>
        </div>
    )
  }
  onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default Wakely
