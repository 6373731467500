import React from 'react'
import './index.scss'
import { 
  Row,
  Col
} from 'antd'
import facebook from '../Images/Social/facebook.svg'
import pinterest from '../Images/Social/pinterest.svg'
import instagram from '../Images/Social/instagram.svg'

function Footer() {
  return (
     <div style={{margin: '30px 50px'}}>
     <Row>
        <Col md={18}>
            <strong>Reach us at <a href="mailto:info@selectionsdesign.com">info@selectionsdesign.com</a> or call us during business hours at <phone>+1 905-629-0001</phone></strong>
            <br/>
            19-5200 Dixie Rd., Mississauga, ON, L4W 1E4
            <br/>
            <br/>
            Selections Interiors and Design reserves the right to change specifications without notice. Illustrations and descriptions are for guidance purposes only and subject to alterations. The reproduction of any part of this website in any form, including but not limited to, photography,imagery,and text, is strongly prohibited and subject to copyright laws.
            <br/><br/>
            Selections Interiors and Design © 1993-2020
         </Col>
         <Col md={6}>
            <div className='social'>
                <a href='https://www.facebook.com/selectionsdesign/' target='_blank' rel="noopener noreferrer">
                    <img src={facebook} alt='selections design facebook'/>
                </a>
                <a href='https://www.instagram.com/selectionsdesign/' target='_blank' rel="noopener noreferrer">
                    <img src={instagram} alt='selections design instagram'/>
                </a>
                <a href='https://www.pinterest.ca/selectionsdesign/' target='_blank' rel="noopener noreferrer">
                    <img src={pinterest} alt='selections design pinterest'/>
                </a>
                
            </div>
            
         </Col>
     </Row>
        
     </div>
  );
}

export default Footer