import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import 'react-awesome-slider/dist/styles.css'
import { Parallax } from 'react-scroll-parallax'
import { 
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Carousel
} from 'antd'
import './index.scss'
import Wakely from '../Wakely'
import GalleryImages from '../Gallery'
import Footer from '../Footer'
import LogoFull from '../Images/logo-full.png'
import slider1  from '../Images/Slider/60.jpg'
import slider2  from '../Images/Slider/56.jpg'
import slider3  from '../Images/Slider/9.jpg'
import slider4  from '../Images/Slider/36.jpg'


const { Content } = Layout
const { Title, Text } = Typography

class Home extends Component {
  render() {
    return (
      <Layout style={{backgroundColor: '#FFF'}} className='home'>
        <a href='/' className='logo'>
          <img src={LogoFull} alt='selections interiors and design'/>
        </a>
        <Content className="site-layout" style={{ padding: 0 , marginTop: 0 }}>
          <div className="site-layout-background" style={{ paddingBottom: 24 }}>
            <Carousel 
              autoplay
            >
              <div>
                <img className='' src={slider1} alt='selections interiors and design' />
              </div>
              <div>
                <img className='' src={slider4} alt='selections interiors and design' />
              </div>
              <div>
                <img className='' src={slider2} alt='selections interiors and design' />
              </div>
              <div>
                <img className='' src={slider3} alt='selections interiors and design' />
              </div>
            </Carousel>
          </div>
          <Row>
          
              <Col 
                xs={{ span: 20, offset: 2 }} 
                md={{ span: 12, offset: 6 }}
              >
                <Parallax y={[10, -10]}>
                  <Card bordered={false} className="card">
                    <Row justify="space-around" align="middle">
                        <Title level={3}>
                          Creativity. Precision. Architectural Woodwork.
                        </Title>
                        <Text>
                        Selections Interiors &amp; Design is a full service design and woodworking agency, proudly servicing the GTA and Muskoka regions for over 30 years. We bring state of the art inspiration from top designers to meet trends in modern and traditional interior design and decor to your door. As a team of creative designers and skilled craftsmen we're on a mission to improve living spaces for Canadians.
                        </Text>
                    </Row>
                  </Card>
                </Parallax>
              </Col>
            </Row>
            <Wakely />
            <GalleryImages />
        </Content>
        <Footer />
      </Layout>
    )
  }
}

export default withRouter(Home)
